// Common
const GET_ME = "/v1/users/me";

// Service Owner
const SO_SIGN_IN = "/v1/service-owners/sign-in";
const SO_SIGN_UP = "/v1/service-owners/sign-up";
const SO_RESEND_OTP = "/v1/service-owners/resend-otp";
const SO_RESEND_OTP_EMAIL = "/v1/service-owners/resend-otp-email";
const SO_VERIFY_OTP = "/v1/service-owners/verify-otp";
const SO_SEND_LINK_VERIFY = "/v1/customers/send-verification";
const SO_SALE_ANALYTIC = "/v1/service-owners/:id/sale-analytic";

// Product
const UPLOAD_IMAGES = "/v1/companies/:id/products/images";
const PRODUCT_LIST = "/v1/companies/:id/products";
const PRODUCT_DETAIL = "/v1/products/:id";
const CREATE_PRODUCT = "/v1/companies/:id/products";
const IMPORT_PRODUCTS = "/v1/companies/:id/products/import";

const ApiPath = {
  GET_ME,
  SO_SIGN_IN,
  SO_SIGN_UP,
  SO_RESEND_OTP,
  SO_RESEND_OTP_EMAIL,
  SO_VERIFY_OTP,
  SO_SEND_LINK_VERIFY,
  SO_SALE_ANALYTIC,
  UPLOAD_IMAGES,
  PRODUCT_LIST,
  PRODUCT_DETAIL,
  CREATE_PRODUCT,
  IMPORT_PRODUCTS,
};

export default ApiPath;
