export default {
  translation: {
    logout: "Đăng Xuất",
    sign_in: "Đăng nhập",
    sign_up: "Đăng ký",
    do_not_have_account: "Chưa có tài khoản? ",
    remember_me: "Ghi nhớ",
    profile: "Hồ sơ",
    dashboard: "Bảng điều khiển",
    products: "Sản phẩm",
    qr_codes: "Mã QR",
    email: "Email",
    email_address: "Địa chỉ email",
    phone_number: "Số điện thoại",
    address: "Địa chỉ",
    loading: "Đang tải...",
    product_list: "Danh sách sản phẩm",
    category_list: "Danh sách danh mục",
    product_number: "STT",
    product_name: "Tên sản phẩm",
    category_name: "Tên danh mục",
    product_price: "Giá",
    product_status: "Trạng thái",
    product_action: "Thao tác",
    add_product_button: "Thêm mới",
    product_import: "Nhập liệu",
    filter: "Lọc",
    forgot_password: "Quên mật khẩu?",
    new_password: "Mật khẩu mới",
    password: "Mật khẩu",
    confirm_password: "Xác nhận mật khẩu",
    submit: "Hoàn tất",
    reset: "Đặt lại",
    auth_title: "Xin chào,",
    auth_subtitle: "Trải nghiệm mới cùng với my-outie",
    otp_title: "Nhập mã OTP",
    otp_subtitle: "Mã OTP đã được gửi đến số điện thoại",
    otp_email: "Mã OTP đã được gửi đến dịa chỉ email",
    continue: "Tiếp tục",
    resend: "Gửi lại mã",
    did_not_receive_otp: "Không nhận được mã OTP? ",
    confirm: "Xác nhận",
    confirmed: "Đã xác nhận",
    seller_name: "Tên người bán",
    company_images: "Hình ảnh công ty",
    service_name: "Tên dịch vụ",
    bank_name: "Tên ngân hàng",
    beneficiary_name: "Tên người thụ hưởng",
    bank_account_number: "Số tài khoản",
    bank_brand_address: "Địa chỉ ngân hàng",
    upload_image_profile: "Thêm ảnh đại diện",
    upload_image_profile_error: "Chọn một hình để tải lên",
    upload_image_product: "Thêm ảnh",
    payment_method_add: "Thêm phương thức thanh toán",
    payment_method_choose: "Chọn phương thức thanh toán",
    payment_method_holder_name: "Họ tên chủ thẻ",
    payment_method_card_number: "Số thẻ",
    payment_method_expire_date: "Ngày hết hạn",
    payment_method_button: "Chấp nhận",
    create_product_title: "Tạo sản phẩm",
    create_category_title: "Tạo danh mục",
    create_product_info: "Thông tin sản phẩm",
    edit_product_title: "Sửa thông tin sản phẩm",
    edit_category_title: "Sửa thông tin danh mục",
    product_detail: "Chi tiết sản phẩm",
    product_info: "Thông tin sản phẩm",
    category_info: "Thông tin danh mục",
    product_quantity: "Số lượng",
    product_discount: "Giảm giá",
    product_amount: "Giá tiền",
    product_percentage: "Phần trăm",
    product_description: "Mô tả",
    product_short_description: "Mô tả ngắn",
    product_create_button: "Tạo",
    product_edit_button: "Chỉnh sửa",
    product_image: "Hình ảnh",
    product_qr_code: "Mã QR",
    user_management: "Quản lí người dùng",
    service_owner_management: "Quản lí cửa hàng",
    placeholder_email: "Nhập email",
    placeholder_phone_number: "Nhập số diện thoại",
    placeholder_password: "Nhập mật khẩu",
    placeholder_new_password: "Nhập mật khẩu mới",
    placeholder_address: "Nhập địa chỉ",
    placeholder_seller_name: "Nhập tên người bán",
    placeholder_service_name: "Nhập tên dịch vụ",
    placeholder_bank_name: "Nhập tên ngân hàng",
    placeholder_beneficiary_name: "Nhập tên người thụ hưởng",
    placeholder_bank_account_number: "Nhập số tài khoản",
    placeholder_bank_brand_address: "Nhập địa chỉ ngân hàng",
    placeholder_create_product_name: "Nhập tên sản phẩm",
    placeholder_create_category_name: "Nhập tên danh mục",
    placeholder_country_code: "ex: +84",
    privacy_policy: "Chính sách bảo mật",
    privacy_label: "Tạo tài khoản nghĩa là bạn đồng ý với",
    terms_of_service: "Điều khoản dịch vụ",
    already_a_member: "Đã có tài khoản?",
    revenue: "Doanh thu",
    total_revenue: "Tổng doanh thu",
    export_to_excel: "Xuất file excel",
    usd: "USD",
    vnd: "VNĐ",
    view_all_products: "Tất cả sản phẩm",
    top_10_products: "Top 10 sản phẩm",
    delete: "Xoá",
    enable: "Cho phép",
    disable: "Vô hiệu hoá",
    deactivate: "Vô hiệu hoá",
    payment_option_title: "Phương thức thanh toán của khách hàng",
    information: "Thông tin",
    no_result: "Không có kết quả",
    print: "In",
    send_to_mail: "Gửi đến email",
    name: "Tên",
    total_customers: "Tổng lượng khách hàng",
    total_service_owners: "Tổng lượng cở sở dịch vụ",
    new_customer: "Khách hàng mới",
    new_service_owner: "Cơ sở dịch vụ mới",
    one_day: "1 Ngày",
    seven_days: "7 Ngày",
    one_month: "1 Tháng",
    six_months: "6 Tháng",
    one_year: "1 Năm",
    edit_service_owner: "Chỉnh sửa cơ sở dịch vụ ",
    export: "Xuất file",
    retail_store: "Cửa hàng bán lẻ",
    total_amount: "Tổng số lượng",
    payment_type: "Loại thanh toán",
    created_at: "Khởi tạo",
    user_details: "Chi tiết người dùng",
    total_purchase_orders: "Tổng hoá đơn đã thanh toán",
    general_information: "Thông tin chung",
    customer_id: "ID người dùng",
    purchase_order_history: "Lịch sử thanh toán",
    payment_method: "Phương thức thanh toán",
    service_packages: "Các gói dịch vụ",
    month: "Tháng",
    show: "Xem",
    item_per_page: "kết quả một trang",
    drag_and_drop_file_here: "Kéo thả file vào đây",
    select_from_computer: "Chọn file từ máy tính",
    images: "Hình ảnh",
    country_code: "Mã vùng",
    cancel: "Huỷ Bỏ",
    // 20211007 DanhVT export order history [
    export_orders_history: "Xuất lịch sử đơn hàng",
    export_orders_history_list: "Xuất danh sách",
    export_orders_history_detail: "Xuất chi tiết",
    // 20211007 DanhVT export order history ]
    // 20211007 DanhVT menu qrcode [
    menus: "Menu",
    menu_name: "Tên menu",
    menu_products: "Sản phẩm",
    menu_created_date: "Ngày tạo",
    menu_status: "Kích hoạt",
    menu_action: "Hành động",
    add_menu_button: "Thêm menu",
    placeholder_menu_name: "Menu 1",
    create_menu_title: "Tạo menu mới",
    create_menu_products: "Sản phẩm",
    create_menu_qrcode: "QR code",
    menu_qrcode_create_first: "Tạo menu để hiện QRCode",
    menu_edit_button: "Lưu",
    menu_create_button: "Lưu",
    menu_delete_confirm_title: "Xác nhận xóa",
    menu_delete_confirm_text: "Bạn có chắc muốn xóa menu này không?",
    menu_delete_confirm: "Xóa",
    menu_delete_cancel: "Hủy",
    menu_deleted: "Đã xóa menu",
    menu_status_updated: "Đã thay đổi trạng thái menu",
    menu_not_exist: "Menu không tồn tại",
    menu_created: "Menu đã được tạo",
    menu_updated: "Menu đã được sửa",
    menu_products_empty: "Chưa có sản phẩm",
    menu_add_products_title: "Thêm sản phẩm",
    // 20211007 DanhVT menu qrcode ]
    personal_information: "Thông tin cá nhân",
    payment_information: "Thông tin thanh toán",
    seller_information: "Thông tin người bán",
    or: "Hoặc",
    receive_otp_via_email: "Gửi mã OTP qua email",
    daily: "Theo Ngày",
    weekly: "Theo Tuần",
    monthly: "Theo Tháng",
    spend: "Chi tiêu",
    download: "Tải xuống",
    download_sample_product_list: "mẫu",
    download_sample_product_list_continue: "để xem",
    download_pdf_file: "Tải xuống PDF",
    edit_seller_profile: "Chỉnh sửa thông tin người bán",
    edit: "Sửa",
    error: "Lỗi!",
    success: "Thành công!",
    failed: "Thất bại!",
    account_disabled:
      "Tài khoản của bạn đã bị vô hiệu hoá. Vui lòng liên hệ với quản trị viên để mở lại tài khoản.",
    file_type_not_support: "Định dạng tập tin không được hỗ trợ",
    select_1_qr: "Xin vui lòng chọn ít nhật 1 mã QR",
    upload_file_error: "Lỗi khi tải lên tập tin",
    so_updated: "Người bán đã bị vô hiệu hoá",
    seller_updated: "Thông tin người bán đã được cập nhật",
    sent_qr_codes:
      "Mã QR đã được gửi đến email của bạn. Vui lòng kiểm tra email.",
    sent_order: "Order đã được gửi đến email của bạn. Vui lòng kiểm tra email.",
    pdf_downloaded: "PDF đã được xuất",
    excel_downloaded: "Excel đã được xuất",
    product_created: "Sản phẩm đã được tạo",
    product_updated: "Sản phẩm đã được cập nhật",
    product_deteled: "Sản phẩm đã bị xoá",
    category_created: "Danh mục đã được tạo",
    category_updated: "Danh mục đã được cập nhật",
    category_deteled: "Danh mục đã bị xoá",
    retail_id: "Mã công ty",
    sort: "Sắp xếp",
    field: "Trường",
    order: "Thứ tự",
    created_date: "Ngày khởi tạo",
    ascending: "Tăng dần",
    descending: "Giảm dần",
    active: "Hoạt động",
    inactive: "Không hoạt động",
    business_license: "Giấy phép đăng ký kinh doanh",
    placeholder_business_license: "Please enter your business license number",
    issue_date: "Ngày cấp",
    placeholder_issue_date: "Please enter issue date of your business license",
    issue_place: "Nơi cấp",
    placeholder_issue_place:
      "Please enter issue place of your business license",
    about_my_okies: "Giới thiệu my-outie",
    company_info_name: "CÔNG TY TNHH VIET INTELLIGENCE",
    company_info_address:
      "Vincom Center Đồng Khởi, Tầng 17-11, 72 Lê Thánh Tôn, Phường Bến Nghé, Quận 1, TP.HCM, Việt Nam",
    company_info_business_license: "0316819243",
    company_info_issue_date: "22/04/2021",
    company_info_issue_place: "Sở Kế hoạch và đầu tư thành phố Hồ Chí Minh",
    company_info_business_email: "contact@v-intel.net",
    company_info_business_website: "v-intel.org",
    change_password: "Đổi mật khẩu",
    admin_password_changed: "Mật khẩu đã được đổi",
    update_personal_information: "Cập nhật thông tin cá nhân",
    current_password: "Mật khẩu hiện tại",
    repeat_new_password: "Nhập lại mật khẩu mới",
    category: "Danh mục",

    store_status: "Trạng thái cửa hàng",
    opening_hours: "Giờ mở cửa",
    monday: "Thứ 2",
    tuesday: "Thứ 3",
    wednesday: "Thứ 4",
    thursday: "Thứ 5",
    friday: "Thứ 6",
    saturday: "Thứ 7",
    sunday: "Chủ nhật",
    edit_opening_hours: "Chỉnh sửa giờ mở cửa",
    days_without_hours_added_will_display_as_opened:
      "Nếu không cài đặt giờ mở cửa, hệ thống sẽ hiển thị mở cửa.",
    open_24_hours: "Mở cửa 24 giờ",
    add_open_hours: "Thêm giờ mở cửa",
    opened: "Đã mở",
    closed: "Đóng cửa",
    opening: "Đang mở",
    closing: "Đang đóng",
    reservation: "Đặt chỗ",
    reservation_settings: "Cài đặt đặt bàn",
    edit_reservation_opening_hours: "Chỉnh sửa giờ mở cửa của đặt bàn",
    reservation_detail: "Chi tiết đặt bàn",
    export_reservations_history: "Xuất lịch sử đặt bàn",
    reservation_deteled: "Đặt chỗ đã bị xoá",
    customer_name: "Tên khách hàng",
    reservation_date: "Ngày đặt bàn",
    order_information: "Thông tin đơn hàng",
    store_name: "Tên cửa hàng",
    store_address: "Địa chỉ cửa hàng",
    store_email: "Email cửa hàng",
    store_phone_number: "Số điện thoại cửa hàng",
    customer_information: "Thông tin khách hàng",
    detail_information: "Thông tin chi tiết",
    date: "Ngày",
    time: "Thời gian",
    total_guest: "Tổng số khách",
    special_request: "Yêu cầu đặc biệt",
    reservation_settings_updated: "Cài đặt đặt bàn đã được cập nhật",
    number_table_available: "Số bàn đang có",
    placeholder_number_table_available: "Vui lòng nhập số bàn đang có",
    total_capacity_per_time_interval:
      "Sức chứa tối đa trong một khoảng phục vụ",
    description_total_capacity_per_time_interval:
      "Tối đa khách có thể phục vụ tại một thời điểm",
    max_customer: "Tối đa khách một lần đặt",
    choose_time: "Vui lòng chọn thời gian",
    choose_category: "Chọn danh mục",
    add_times: "Thêm thời gian",
    reservation_period: "Thời gian đặt trước",
    reservation_period_error: "Thời gian đặt trước là bắt buộc",
    placeholder_reservation_period: "Vui lòng nhập thời gian đặt trước",
    time_interval: "Thời gian cách nhau",
    time_interval_error: "Thời gian cách nhau là bắt buộc",
    placeholder_time_interval: "Vui lòng nhập thời gian cách nhau",
    max_guest_per_reserve: "Tối đa khách một lần đặt",
    max_guest_per_reserve_error: "Tối đa khách một lần đặt là bắt buộc",
    placeholder_max_guest: "Vui lòng nhập tối đa khách một lần đặt",
    orders: "Đơn hàng",
    print_order: "In đơn hàng",
    order_date: "Ngày đơn hàng",
    total_payment: "Tổng thanh toán",
    placeholder_customer_name: "Tìm kiếm tên khách hàng",
    placeholder_product_name: "Tìm kiếm tên sản phẩm",
    search_menu_name: "Tìm kiếm tên thực đơn",
    order_detail: "Chi tiết đơn hàng",
    order_no: "Mã đơn hàng",
    delivery_option: "Phương thức nhận hàng",
    payment_status: "Tình trạng thanh toán",
    total_price: "Tổng tiền",
    reservation_updated: "Đặt bàn đã được cập nhật",
    day: "Ngày",
    week: "Tuần",
    year: "Năm",
    minute: "Phút",
    minutes: "Phút",
    hour: "Giờ",
    hours: "Giờ",
    draft: "Mới tạo",
    all: "Tất cả",
    cancelled: "Huỷ Bỏ",
    pending: "Đang xử lý",
    paid: "Đã thanh toán",
    verify_otp: "Vui lòng xác nhận mã OTP",
    receive_otp_via_phone_number: "Gửi mã OTP qua số điện thoại",
    please_input: "Vui lòng nhập {{name}}",
    email_invalid: "Địa chỉ email không hợp lệ",
    password_min_6_characters: "Mật khẩu tối thiểu là 6 ký tự",
    country_code_invalid: "Mã vùng không hợp lệ",
    phone_number_invalid: "Số điện thoại không hợp lệ",
    remember_password: "Lưu mật khẩu",
    order_list: "Danh sách đơn hàng",
    to: "đến",
    remove: "Gỡ bỏ",
    service_method: "Phương thức dịch vụ",
    table: "Tại bàn",
    location: "Địa điểm",
    pick_up: "Tới lấy",
    delivery: "Giao hàng",
    delivery_address: "Địa chỉ giao hàng",
    note: "Ghi chú",
    confirm_payment: "Xác nhận thanh toán",
    update_order_status_completed: "Xác nhận thanh toán đơn hàng thành công",
    customer_payment_accept_options: "Các hình thức thanh toán",
    cash: "Tiền mặt",
    credit_card: "Thẻ Credit",
    atm_card: "Thẻ ATM",
    sale_details: "Chi tiết bán hàng",
    add_images: "Thêm hình ảnh",
    maximum_20_images: "Hình ảnh công ty tối đa là 20 hình",
    minimum_price: "Giá tiền tối thiểu 20.000 VNĐ",
    minimum_discount: "Giảm giá tối thiểu là 20.000 VNĐ",
    discount_percent_error: "Phần trăm khuyến mãi không lớn hơn 100%",
    discount_bigger_price: "Giá trị khuyến mãi không lớn hơn giá trị sản phẩm",
    discount_negative_error: "Vui lòng nhập số dương",
    back_to_menu: "Quay lại trang menu",
    about_us_title: "Tương lai của Hệ thống POS và Thu ngân",
    about_us_content: "Chuyển đổi hoạt động kinh doanh của bạn với ",
    about_us_content_2:
      "- giải pháp tự động hóa tất cả trong một giúp hợp lý hóa quy trình đặt hàng và thanh toán. Công nghệ tiên tiến của chúng tôi, sử dụng mã QR kỹ thuật số cho menu, dịch vụ và sản phẩm, mang lại trải nghiệm dịch vụ khách hàng đặc biệt, nâng cao hiệu quả và giảm chi phí vận hành. Đưa doanh nghiệp của bạn lên một tầm cao mới với ",
    register_business_account: "Đăng ký tài khoản doanh nghiệp",
    about_us_address:
      "Vincom Center Đồng Khởi, Tầng 17-11, 72 Lê Thánh Tôn, Phường Bến Nghé, Quận 1, TP.HCM, Việt Nam",
    scan_qr_code_to_order_and_pay: "Quét mã QR để đặt hàng và thanh toán",
    online_and_offline_sales_in_one_app:
      "Bán hàng trực tuyến và ngoại tuyến trong một ứng dụng",
    unique_luxury_design: "Thiết Kế Sang Trọng Độc Đáo",
    modern_automation_technology: "Công nghệ tự động hóa hiện đại",
    multiple_payment_options: "Nhiều phương thức thanh toán",
    online_operation_management: "Quản lý vận hành trực tuyến",
    fast_order_and_secure_payment: "Đặt hàng nhanh và thanh toán an toàn",
    customer_loyalty_program: "Chương trình khách hàng thân thiết",
    digital_qr_menu_or_product_list:
      "Menu QR kỹ thuật số hoặc Danh sách sản phẩm",
    more_customers_more_profit: "Nhiều khách hàng hơn. Thêm lợi nhuận",
    dedicate_for_hight_end_customer_shopping_and_dining_experience:
      "Dành cho trải nghiệm mua sắm và ăn uống của khách hàng cao cấp",
    reduce_operation_cost: "Giảm chi phí vận hành",
    access_24_7_with_auto_sales_reports:
      "Truy cập 24/7 với Báo cáo bán hàng tự động",
    improve_customer_service_experience:
      "Cải thiện trải nghiệm dịch vụ khách hàng",
    e_wallets_credit_debit_cards_and_cash_option:
      "Ví điện tử, Thẻ tín dụng/Thẻ ghi nợ và Tùy chọn tiền mặt.",
    reward_your_customers_width_point_redeemption_discount_price_and_many_more:
      "Thưởng cho khách hàng của bạn bằng cách đổi điểm, giảm giá và nhiều hơn nữa.",
    main_features: "Những tính năng chính",
    business_application: "Ứng dụng kinh doanh",
    food_and_beverage_businesses: "Doanh nghiệp thực phẩm và đồ uống",
    food_and_beverage_businesses_content:
      "Từ nhà hàng đến quán cà phê, xử lý đơn hàng nhanh chóng với my-outie - QR Menu. Chỉ cần quét QR Code để đặt hàng và thanh toán trực tiếp trên điện thoại. Quản lý doanh nghiệp của bạn theo thời gian thực, báo cáo bán hàng tự động miễn phí hàng ngày, hàng tháng và hàng năm. Giảm thiểu chi phí vận hành và cải thiện trải nghiệm dịch vụ khách hàng của bạn.",
    retail_business: "Kinh doanh bán lẻ",
    retail_business_content:
      "Đặt doanh nghiệp của bạn khác biệt với những doanh nghiệp khác bằng dịch vụ công nghệ của chúng tôi để bán hàng trực tuyến và tại cửa hàng bằng cách mua qua Mã QR với thông tin sản xuất. Dễ dàng vận hành và quản lý cửa hàng của bạn hoàn toàn trực tuyến, truy cập 24/7 và chấp nhận tất cả các loại tùy chọn thanh toán từ ví điện tử đến tất cả thẻ ghi nợ/thẻ tín dụng với my-outie. Nâng cao trải nghiệm mua sắm của khách hàng, chỉ cần quét mã QR để đặt hàng, thanh toán trực tiếp trên ứng dụng và nhận hàng ngay.",
    service_businesses: "Doanh nghiệp dịch vụ",
    service_businesses_content:
      "Nhanh chóng và dễ dàng để đặt hàng và thanh toán cho dịch vụ của bạn với my-outie bằng cách quét Mã QR cho Danh sách Dịch vụ. Quản lý tất cả các khía cạnh kinh doanh của bạn hoàn toàn trực tuyến, truy cập thông tin chi tiết quản lý của bạn từ mọi nơi và mọi lúc. Cải thiện trải nghiệm dịch vụ khách hàng của bạn một cách đơn giản với mã QR có thể quét được để đặt hàng và thanh toán trực tiếp trên điện thoại của họ cũng như lên lịch các cuộc hẹn cho doanh nghiệp của bạn chỉ bằng vài cú nhấp chuột.",
    restaurant: "Nhà hàng",
    coffee_shop: "Quán cà phê",
    bar_lounge: "Quầy bar, Phòng chờ",
    hotel_resort_in_room_dining_service:
      "Dịch Vụ Ăn Tại Phòng Khách Sạn/Resort",
    dessert_ice_cream_bakery_shop: "Món Tráng Miệng, Kem, Tiệm Bánh",
    home_decor_furniture: "Trang Trí Nhà Cửa, Nội Thất",
    wine_spirit_and_cigar: "Rượu, rượu mạnh và xì gà",
    flower_boutique_shop: "Cửa hàng hoa nhỏ",
    airport_duty_free: "Miễn thuế sân bay",
    book_store: "Tiệm sách",
    cosmetic: "Mỹ phẩm",
    art_gallery: "Triển lãm nghệ thuật",
    beauty_salon_hair_spa_nails_massage:
      "Thẩm mỹ viện - Tóc, Spa, Móng, Massage",
    hotel_resort_in_room_spa_service: "Dịch Vụ Spa Tại Phòng Khách Sạn/Resort",
    gym_membership: "Thành viên phòng tập thể dục",
    event_ticket: "Vé sự kiện",
    health_care_clinic_hospital: "Chăm sóc sức khỏe, phòng khám, bệnh viện",
    vet_service: "Dịch vụ thú y",
    casino_gambling_chips: "Sòng bạc cờ bạc",
    our_service_is_currently_available_to:
      "Dịch vụ của chúng tôi hiện có sẵn cho:",
    business_brochure: "Business Brochure",
    orders_are_recorded_by_myokies_system_directly_from_customer_to_business_owner_management_dashboard:
      "Đơn hàng được hệ thống my-outie ghi nhận trực tiếp từ khách hàng đến bảng điều khiển quản lý chủ doanh nghiệp",
    eliminate_missing_order: "Loại bỏ đơn hàng bị thiếu",
    inflight_dining: "Bữa ăn trên chuyến bay",
    viewed_orders: "Đơn hàng đã xem",
    unviewed_orders: "Đơn hàng chưa xem",
    unviewed: "Chưa xem",
    viewed: "Đã xem",
    enter_your_phone_number: "Nhập số điện thoại của bạn",
    enter_your_email: "Nhập email của bạn",
    enter_otp_send_to: "Nhập OTP gửi đến",
    sent_otp: "Chúng tôi đã gửi (OTP)",
    to_your: "đến {{text}}",
    are_you_sure_you_want_to_logout: "Bạn có chắc chắn bạn muốn đăng xuất?",
    reset_password_expired: "Liên kết đặt lại mật khẩu đã hết hạn",
    verify_account:
      "Vui lòng xác minh tài khoản của bạn, liên kết xác minh đã được gửi đến email của bạn!",
    this_field_is_required: "Trường này là bắt buộc",
    this_field_should_be_a_number: "Trường này phải là số",
    your_account_has_been_verified: "Tài khoản của bạn đã được xác nhận",
    reset_password: "Đặt lại mật khẩu",
    processing: "Đang xử lý",
    back_to_page: "Quay lại trang",
    delete_account: "Xoá tài khoản",
    delete_account_note: "Tài khoản của bạn sẽ bị xóa vĩnh viễn",
    notification: "Thông báo",
    you_have_new_order: "Bạn có đơn hàng mới!",
    you_have_new_reservation: "Bạn có lịch đặt mới!",
    banner_chart_title:
      "Bảng so sánh giữa <span class='important'>my-outie</span> và hệ thống POS",
    Calendar: "Lịch",
    please_upload_picture_with_size_smaller_than_3mb_per_picture:
      "Vui lòng tải lên ảnh có kích thước nhỏ hơn 3 MB mỗi ảnh",
    booking_date: "Ngày đặt",
    product_management: "Quản lý sản phẩm",
    register_successful:
      "Chào mừng đến với my-outie! Vui lòng đợi nhóm my-outie xử lý đăng ký của bạn",
    pending_for_approval: "Đang chờ duyệt",
    create_seller_account: "Tạo tài khoản người bán",
    sign_out: "Đăng xuất",
    chat: "Chat",
    search_name: "Tìm kiếm tên",
    no_conversation: "Không có cuộc trò chuyện nào",
    no_message: "Không có tin nhắn nào",
    you_are_discussing_this_product: "Bạn đang thảo luận sản phẩm này",
    copy_successfully: "Sao chép thành công",
    see_more: "Xem nhiều hơn",
    see_less: "Thu gọn",
    order_id: "ID đơn hàng",
    type_something: "Nhập nội dung gì đó...",
    you_have_been_blocked_by_this_user: "Bạn đã bị chặn bởi người dùng này",
    you_have_blocked_this_user: "Bạn đã chặn người dùng này",
    block: "Chặn",
    unblock: "Bỏ chặn",
    do_you_want_to_block_this_conversation:
      "Bạn có muốn chặn cuộc trò chuyện này?",
    do_you_want_to_unblock_this_conversation:
      "Bạn có muốn bỏ chặn cuộc trò chuyện này?",
    if_you_block_you_will_no_longer_be_able_to_message_this_person:
      "Nếu bạn chặn, bạn sẽ không thể nhắn tin cho người này được nữa",
    if_you_unblock_you_can_message_this_person:
      "Nếu bạn bỏ chặn, bạn có thể nhắn tin cho người này",
    you_have_a_new_message: "Bạn có 1 tin nhắn mới",
    the_store_has_been_disabled: "Cửa hàng đã bị vô hiệu hóa",
  },
};
