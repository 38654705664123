import { SERVICE_OWNER_ROUTES } from "../../routes/routeConst";
import { handleServerError } from "../../utils/error";
import Api from "../api";
import ApiConstant from "../api/api.constant";
import ApiPath from "../api/api.path";

const getMe = async () => {
  try {
    const resp = await Api({
      url: ApiPath.GET_ME,
    });

    const userInfo = resp?.data?.user_profile;
    const statusCode = resp?.data?.statusCode;

    if (statusCode === 200 && userInfo) {
      return userInfo;
    }

    return null;
  } catch (error) {
    handleServerError(error);
    localStorage.removeItem(ApiConstant.ACCESS_TOKEN);
    localStorage.removeItem(ApiConstant.TEMP_ACCESS_TOKEN);
    location.href = SERVICE_OWNER_ROUTES.AUTH;
  }
};

const update = async (data: Record<string, any>) => {
  try {
    const resp = await Api({
      url: "/v1/customers/profiles",
      method: "PATCH",
      data,
    });

    return resp.data;
  } catch (error) {
    throw error;
  }
};

const changePassword = async (data: Record<string, string>) => {
  try {
    const resp = await Api({
      url: "/v1/service-owners/change-password",
      method: "PATCH",
      data,
    });

    return resp.data;
  } catch (error) {
    throw error;
  }
};

const deleteAccount = async (userId: string) => {
  try {
    const resp = await Api({
      url: `/v1/customers/${userId}`,
      method: "DELETE",
    });

    return resp.data;
  } catch (error) {
    return error;
  }
};

const UserRepo = {
  getMe,
  update,
  changePassword,
  deleteAccount,
};

export default UserRepo;
