export const SERVICE_OWNER_ROUTES = {
  HOME: "/",
  DASHBOARD: "/dashboard",
  AUTH: "/auth",
  SIGN_IN: "/auth/sign-in",
  SIGN_UP: "/auth/sign-up",
  OTP: "/auth/otp",
  CREATE_COMPANY: "/create-company",
  PRODUCTS: "/products",
  EDIT_PRODUCT: "/edit-product",
  PRODUCTS_DETAILS: "/products/:id",
  NEW_PRODUCT: "/new-product",
  PAYMENTS: "/payments",
  NEW_PAYMENT: "/new-payment",
  QR_CODE: "/qr-code",
  MENUS: "/menus",
  MENU_WITHOUT_ID: "/menu/",
  MENU: "/menu/:id?",
  SERVICE_PACKAGES: "/service-packages",
  PROFILE: "/profile",
  EDIT_COMPANY_PROFILE: "/edit-company-profile",
  EDIT_PERSONAL_PROFILE: "/edit-personal-profile",
  ABOUT: "/about",
  CATEGORY: "/category",
  NEW_CATEGORY: "/new-category",
  EDIT_CATEGORY: "/category/:id",
  EDIT_OPENING_HOURS: "/edit-opening-hours",
  RESERVATION: "/reservation",
  RESERVATION_SETTINGS: "/reservation-settings",
  RESERVATION_OPENING_HOURS: "/reservation-opening-hours",
  RESERVATION_DETAILS: "/reservation/:id",
  ORDERS: "/orders",
  ORDERS_DETAILS: "/orders/:id",
  CHAT: "/chat",

};

export const PUBLIC_ROUTES = {
  TERMS_OF_USE: "/terms-of-use",
  TERMS_OF_USE_VI: "/terms-of-use/vi",
  PRIVACY: "/privacy",
  PRIVACY_VI: "/privacy/vi",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  NOT_FOUND: "*",
};
