import { Modal, Spin } from "antd";
import React, { useState } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

import { CommonAtom } from "../../../service/common";

import "./ConfirmModal.scss";

const ConfirmModal: React.FC = () => {
  const { t } = useTranslation();
  const showConfirmModal = useRecoilValue(CommonAtom.showConfirmModal);
  const resetShowConfirmModal = useResetRecoilState(
    CommonAtom.showConfirmModal
  );
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);

    try {
      await showConfirmModal?.onConfirm?.();
    } finally {
      resetShowConfirmModal();
      setLoading(false);
    }
  };

  return (
    <Modal
      destroyOnClose
      open={showConfirmModal.open}
      footer={false}
      title={false}
      closeIcon={false}
      closable={false}
      centered
      width={520}
    >
      <div className="confirm_modal">
        <h2 className="title">{showConfirmModal.title}</h2>

        <p className="description">{showConfirmModal.description}</p>

        <div className="btns">
          <button
            className={`btn w-100 btn-outline cursor-pointer`}
            onClick={resetShowConfirmModal}
          >
            {t("menu_delete_cancel")}
          </button>

          <button
            className={`btn w-100 btn-primary cursor-pointer ${
              loading ? "btn-disabled cursor-not-allowed" : ""
            }`}
            onClick={handleConfirm}
            disabled={loading}
          >
            {loading ? <Spin /> : t("confirm")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
