export default {
  translation: {
    logout: "Logout",
    sign_in: "Sign In",
    sign_up: "Sign Up",
    do_not_have_account: "Don't have an account? ",
    remember_me: "Remember me",
    profile: "Profile",
    dashboard: "Dashboard",
    products: "Products",
    qr_codes: "QR Codes",
    email: "Email",
    email_address: "Email Address",
    phone_number: "Phone Number",
    address: "Address",
    loading: "Loading...",
    product_list: "Product List",
    category_list: "Category List",
    product_number: "No.",
    product_name: "Product Name",
    category_name: "Category Name",
    product_price: "Price",
    product_status: "Status",
    product_action: "Action",
    add_product_button: "Add new",
    product_import: "Import",
    filter: "Filter",
    forgot_password: "Forgot your password?",
    new_password: "New password",
    password: "Password",
    confirm_password: "Confirm password",
    submit: "Submit",
    reset: "Reset",
    auth_title: "Welcome,",
    auth_subtitle: "The new experience with my-outie",
    otp_title: "Enter your OTP",
    otp_subtitle: "OTP code has been sent to phone number",
    otp_email: "OTP code has been sent to email address",
    continue: "Continue",
    resend: "Resend Now",
    did_not_receive_otp: "Didn't receive your OTP code? ",
    confirm: "Confirm",
    confirmed: "Confirmed",
    seller_name: "Seller Name",
    company_images: "Company Images",
    service_name: "Service Name",
    bank_name: "Bank Name",
    beneficiary_name: "Beneficiary Name",
    bank_account_number: "Bank Account Number",
    bank_brand_address: "Bank Brand Address",
    upload_image_profile: "Upload new image profile",
    upload_image_profile_error: "Please upload a logo",
    upload_image_product: "Add image",
    payment_method_add: "Add new payment method",
    payment_method_choose: "Choose payment method",
    payment_method_holder_name: "Holder Name",
    payment_method_card_number: "Card Number",
    payment_method_expire_date: "Expiration Date",
    payment_method_button: "Make Payment",
    create_product_title: "Create new product",
    create_category_title: "Create new category",
    create_product_info: "Product Information",
    edit_product_title: "Edit product detail",
    edit_category_title: "Edit category detail",
    product_detail: "Product detail",
    product_info: "Product Information",
    category_info: "Category Information",
    product_quantity: "Quantity",
    product_discount: "Discount",
    product_amount: "Amount",
    product_percentage: "Percentage",
    product_description: "Description",
    product_short_description: "Short Description",
    product_create_button: "Create",
    product_edit_button: "Edit",
    product_image: "Image",
    product_qr_code: "QR code",
    user_management: "Customer Management",
    service_owner_management: "Service Owner Management",
    placeholder_email: "Enter your email",
    placeholder_phone_number: "Enter your phone number",
    placeholder_password: "Enter your password",
    placeholder_new_password: "Enter new password",
    placeholder_address: "Enter your address",
    placeholder_seller_name: "Enter seller name",
    placeholder_service_name: "Enter service name",
    placeholder_bank_name: "Enter bank name",
    placeholder_beneficiary_name: "Enter beneficiary name",
    placeholder_bank_account_number: "Enter bank account number",
    placeholder_bank_brand_address: "Enter bank brand address",
    placeholder_create_product_name: "Enter product name",
    placeholder_create_category_name: "Enter category name",
    placeholder_country_code: "ex: +84",
    privacy_label: "Creating account means you're okay with",
    terms_of_service: "Terms of Service",
    already_a_member: "Already a member?",
    revenue: "Revenue",
    total_revenue: "Total revenue",
    export_to_excel: "Export to Excel",
    usd: "USD",
    vnd: "VND",
    view_all_products: "View all products",
    top_10_products: "Top 10 Products",
    delete: "Delete",
    enable: "Enable",
    disable: "Disable",
    deactivate: "Deactivate",
    payment_option_title: "Customer Payment Options",
    information: "Information",
    no_result: "No result",
    print: "Print",
    send_to_mail: "Sent to email",
    name: "Name",
    total_customers: "Total Customers",
    total_service_owners: "Total Service Owners",
    new_customer: "New customer",
    new_service_owner: "New Service Owner",
    one_day: "1 Day",
    seven_days: "7 Days",
    one_month: "1 Month",
    six_months: "6 Months",
    one_year: "1 Year",
    edit_service_owner: "Edit Service Owner ",
    export: "Export",
    retail_store: "Retail Store",
    total_amount: "Total Amount",
    payment_type: "Payment Type",
    created_on: "Created On",
    user_details: "User Details",
    total_purchase_orders: "Total Purchased Orders",
    general_information: "General Information",
    customer_id: "Customer ID",
    purchase_order_history: "Purchase Order History",
    payment_method: "Payment Method",
    service_packages: "Service Packages",
    month: "Month",
    show: "Show",
    item_per_page: "items per page",
    drag_and_drop_file_here: "Drag and drop files here",
    select_from_computer: "Select file from computer",
    images: "Images",
    country_code: "Country Code",
    cancel: "Cancel",
    export_orders_history: "Export order history",
    export_orders_history_list: "Export list",
    export_orders_history_detail: "Export Detail",
    menus: "Menu",
    menu_name: "Name",
    menu_products: "Products",
    menu_created_date: "Created date",
    menu_status: "Active",
    menu_action: "Action",
    add_menu_button: "Add new",
    placeholder_menu_name: "Menu 1",
    create_menu_title: "Create new menu",
    create_menu_products: "Products",
    create_menu_qrcode: "QR code",
    menu_qrcode_create_first: "Create menu first to show QRCode",
    menu_edit_button: "Save",
    menu_create_button: "Save",
    menu_delete_confirm_title: "Confirm delete",
    menu_delete_confirm_text: "Are you sure delete this menu?",
    menu_delete_confirm: "Delete",
    menu_delete_cancel: "Cancel",
    menu_deleted: "Delete success",
    menu_status_updated: "Menu status changed",
    menu_not_exist: "Menu doesn't exist",
    menu_created: "Menu created",
    menu_updated: "Menu updated",
    menu_products_empty: "Product empty",
    menu_add_products_title: "Add products",
    personal_information: "Personal Information",
    payment_information: "Payment Information",
    seller_information: "Seller Information",
    or: "Or",
    receive_otp_via_email: "Receive OTP via email",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    spend: "Amount",
    download: "Download",
    download_sample_product_list: "this sample product list upload",
    download_sample_product_list_continue:
      "to see what detail should be included",
    download_pdf_file: "Download PDF",
    edit_seller_profile: "Edit seller profile",
    edit: "Edit",
    error: "Error!",
    success: "Success!",
    failed: "Failed!",
    account_disabled:
      "You account has been disabled. Please contact admin to re-enable your account.",
    file_type_not_support: "File type not support",
    select_1_qr: "Please select at least one QR code",
    upload_file_error: "Upload file error",
    so_updated: "Service Owner has been updated",
    seller_updated: "Seller information has been updated",
    sent_qr_codes:
      "Selected QR codes have been send to your email, kindly check your email.",
    sent_order: "Order have been send to your email, kindly check your email.",
    pdf_downloaded: "PDF file has been exported",
    excel_downloaded: "Excel file has been exported",
    product_created: "Product has been created",
    product_updated: "Product has been updated",
    product_deteled: "Product has been deleted",
    category_created: "Category has been created",
    category_updated: "Category has been updated",
    category_deteled: "Category has been deleted",
    retail_id: "Retail ID",
    sort: "Sort",
    field: "Field",
    order: "Order",
    created_date: "Created Date",
    ascending: "Ascending",
    descending: "Descending",
    active: "Active",
    inactive: "Inactive",
    business_license: "Business License Number",
    placeholder_business_license: "Please enter your business license number",
    issue_date: "Issue Date",
    placeholder_issue_date: "Please enter issue date of your business license",
    issue_place: "Issue Place",
    placeholder_issue_place:
      "Please enter issue place of your business license",
    about_my_okies: "About my-outie",
    company_info_name: "VIET INTELLIGENCE COMPANY LIMITED",
    company_info_address:
      "Vincom Center Dong Khoi - Floor 17 - 72 Le Thanh Ton St, Ben Nghe Ward, District 1, HCMC, Vietnam",
    company_info_business_license: "0316819243",
    company_info_issue_date: "22/04/2021",
    company_info_issue_place:
      "Department of Planning and Investment of Ho Chi Minh City",
    company_info_business_email: "contact@v-intel.net",
    company_info_business_website: "v-intel.org",
    change_password: "Change Password",
    admin_password_changed: "Password has been changed",

    terms_of_use: "Terms of Use - my-outie APPLICATION",
    privacy_policy: "Privacy Policy",
    introduction: "INTRODUCTION",
    update_personal_information: "Edit personal information",
    current_password: "Current password",
    repeat_new_password: "Repeat new password",
    category: "Category",

    store_status: "Store Status",
    opening_hours: "Opening hours",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    edit_opening_hours: "Edit Opening Hours",
    days_without_hours_added_will_display_as_opened:
      "Days without hours added will display as opened.",
    open_24_hours: "Open 24 hours",
    add_open_hours: "Add open hours",
    opened: "Opened",
    closed: "Closed",
    reservation: "Reservation",
    reservation_settings: "Reservation Settings",
    edit_reservation_opening_hours: "Edit Reservation Opening Hours",
    reservation_detail: "Reservation Detail",
    export_reservations_history: "Export reservation history",
    reservation_deteled: "Reservation has been deleted",
    customer_name: "Customer Name",
    reservation_date: "Reservation Date",
    order_information: "Order Information",
    store_name: "Store Name",
    store_address: "Store Address",
    store_email: "Store Email",
    store_phone_number: "Store Phone Number",
    customer_information: "Customer Information",
    detail_information: "Detail Information",
    date: "Date",
    time: "Time",
    total_guest: "Total Guest",
    special_request: "Special Request",
    reservation_settings_updated: "Reservation Settings has been updated",
    number_table_available: "Number Table Available",
    placeholder_number_table_available: "Enter Number Table Available",
    total_capacity_per_time_interval: "Total Capacity Per Time Interval",
    description_total_capacity_per_time_interval:
      "Max customer restaurant can handle at specific time",
    max_customer: "Max Customer",
    choose_time: "Choose time",
    choose_category: "Choose category",
    add_times: "Add times",
    reservation_period: "Reservation Period",
    reservation_period_error: "Reservation Period is required",
    placeholder_reservation_period: "Enter Reservation Period",
    time_interval: "Time Interval",
    time_interval_error: "Time Interval is required",
    placeholder_time_interval: "Enter Time Interval",
    max_guest_per_reserve: "Max Guest per Reserve",
    max_guest_per_reserve_error: "Max Guest per Reserve is required",
    placeholder_max_guest: "Enter Max Guest",
    orders: "Orders",
    print_order: "Print Order",
    order_date: "Order Date",
    total_payment: "Total Payment",
    placeholder_customer_name: "Search customer name",
    placeholder_product_name: "Search product name",
    search_menu_name: "Search menu name",
    order_detail: "Order Detail",
    order_no: "Order No",
    delivery_option: "Delivery Option",
    payment_status: "Payment status",
    total_price: "Total Price",
    reservation_updated: "Reservation has been updated",
    day: "Day",
    week: "Week",
    year: "Year",
    minute: "Minute",
    minutes: "Minutes",
    hour: "Hour",
    hours: "Hours",
    draft: "Draft",
    all: "All",
    cancelled: "Cancelled",
    pending: "Pending",
    paid: "Paid",
    verify_otp: "Please verify OTP",
    receive_otp_via_phone_number: "Receive OTP via phone number",
    please_input: "Please input your {{name}}",
    email_invalid: "Your email is not valid",
    password_min_6_characters: "The password must be at least 6 characters",
    country_code_invalid: "Country Code is not valid",
    phone_number_invalid: "Phone number is not valid",
    remember_password: "Remember password",
    order_list: "Order List",
    to: "to",
    remove: "Remove",
    service_method: "Service Method",
    table: "Table",
    location: "Location",
    pick_up: "Pick-up",
    delivery: "Delivery",
    delivery_address: "Delivery Address",
    note: "Note",
    confirm_payment: "Confirm payment",
    update_order_status_completed: "Order payment confirmation successful",
    customer_payment_accept_options: "Customer Payment Accept Options",
    cash: "Cash",
    credit_card: "Credit Card",
    atm_card: "ATM Card",
    sale_details: "Sales Details",
    add_images: "Add images",
    maximum_20_images: "Maximum company image is 20 images",
    minimum_price: "Minimum price is 20.000 VNĐ",
    minimum_discount: "Minimum discount is 20.000 VNĐ",
    discount_percent_error: "Discount percent should not bigger than 100%",
    discount_bigger_price: "Discount should not bigger than the price",
    discount_negative_error: "Discount should be a positive number",
    back_to_menu: "Back To Menu",
    about_us_title: "The Future of POS Systems and Cashiers",
    about_us_content: "Transform your business operations with ",
    about_us_content_2:
      "- the all-in-one automation solution that streamlines the ordering and payment process. Our cutting-edge technology, using digital QR codes for menus, services, and products, delivers an exceptional customer service experience, improves efficiency, and reduce operation costs. Take your business to the next level with ",
    register_business_account: "Register Business Account",
    about_us_address:
      "Vincom Center Dong Khoi, Floor 17-11, 72 Le Thanh Ton, Ben Nghe Ward, District 1, HCMC, Vietnam",
    scan_qr_code_to_order_and_pay: "Scan QR Code to Order and Pay",
    online_and_offline_sales_in_one_app: "Online and Offline Sales in One App",
    unique_luxury_design: "Unique Luxury Design",
    modern_automation_technology: "Modern Automation Technology",
    multiple_payment_options: "Multiple Payment Options",
    online_operation_management: "Online Operation Management",
    fast_order_and_secure_payment: "Fast Order and Secure Payment",
    customer_loyalty_program: "Customer Loyalty Program",
    digital_qr_menu_or_product_list: "Digital QR Menu or Product List",
    more_customers_more_profit: "More Customers. More Profit",
    dedicate_for_hight_end_customer_shopping_and_dining_experience:
      "Dedicate for High-End Customer Shopping and Dining Experience",
    reduce_operation_cost: "Reduce Operation Cost",
    access_24_7_with_auto_sales_reports: "Access 24/7 with Auto Sales Reports",
    improve_customer_service_experience: "Improve Customer Service Experience",
    e_wallets_credit_debit_cards_and_cash_option:
      "E-Wallets, Credit/Debit Cards, and Cash Option.",
    reward_your_customers_width_point_redeemption_discount_price_and_many_more:
      "Reward Your Customers with Point Redeemption, Discount Price and many more.",
    main_features: "Main Features",
    business_application: "Business Application",
    food_and_beverage_businesses: "Food and Beverage Businesses",
    food_and_beverage_businesses_content:
      "From restaurants to coffee shops, process orders quickly with my-outie - QR Menu. Simply scan QR Code to order and pay direclty on the phone. Manage your business in real time, free auto sales reports daily, monthly and yearly. Minimize your operation cost, and improve your customer service exprience.",
    retail_business: "Retail Business",
    retail_business_content:
      "Set your business apart from others with our technology service for online and in-store sales by purchasing through QR Code with the production information. Easily operate and manage your store entirely online, access 24/7 and accept all types of payment options from e-wallet to all debit/credit cards with my-outie. Improve your customer shopping exprience, simply scan QR code to order, pay directly on app and receive the orders immediately.",
    service_businesses: "Service Businesses",
    service_businesses_content:
      "Quick and easy to order and pay for your service with my-outie by scanning QR Code for Service List. Manage all aspects of your business completely online, access your management details from anywhere and anytime. Improve your customer service experience simply with a scannable QR code to order and pay directly on their phone and schedule appointments for your business with just a few clicks.",
    restaurant: "Restaurant",
    coffee_shop: "Coffee Shop",
    bar_lounge: "Bar, Lounge",
    hotel_resort_in_room_dining_service: "Hotel/Resort In-Room Dining Service",
    dessert_ice_cream_bakery_shop: "Dessert, Ice Cream, Bakery Shop",
    home_decor_furniture: "Home Decor, Furniture",
    wine_spirit_and_cigar: "Wine, Spirit and Cigar",
    flower_boutique_shop: "Flower Boutique Shop",
    airport_duty_free: "Airport Duty Free",
    book_store: "Bookstore",
    cosmetic: "Cosmetic",
    art_gallery: "Art Gallery",
    beauty_salon_hair_spa_nails_massage:
      "Beauty Salon - Hair, Spa, Nails, Massage",
    hotel_resort_in_room_spa_service: "Hotel/Resort In-Room Spa Service",
    gym_membership: "Gym Membership",
    event_ticket: "Event Ticket",
    health_care_clinic_hospital: "Health Care, Clinic, Hospital",
    vet_service: "Vet Service",
    casino_gambling_chips: "Casino Gambling Chips",
    our_service_is_currently_available_to:
      "Our service is currently available to:",
    business_brochure: "Business Brochure",
    orders_are_recorded_by_myokies_system_directly_from_customer_to_business_owner_management_dashboard:
      "Orders are recorded by my-outie system directly from customer to bussiness owner management dashboard",
    eliminate_missing_order: "Eliminate Missing Order",
    inflight_dining: "Inflight Dining",
    viewed_orders: "Viewed Orders",
    unviewed_orders: "Unviewed Orders",
    unviewed: "Unviewed",
    viewed: "Viewed",
    enter_your_phone_number: "Enter your phone number",
    enter_your_email: "Enter your email",
    enter_otp_send_to: "Enter OTP send to",
    sent_otp: "We have sent a one-time password (OTP)",
    to_your: "to your {{text}}",
    are_you_sure_you_want_to_logout: "Are you sure you want to logout?",
    reset_password_expired: "Password reset link has expired",
    verify_account:
      "Please verify your account, the verify link has been sent to your email!",
    this_field_is_required: "This field is required",
    this_field_should_be_a_number: "This field should be a number",
    your_account_has_been_verified: "Your account has been verified",
    reset_password: "Reset Password",
    processing: "Processing",
    back_to_page: "Back to page",
    delete_account: "Delete account",
    delete_account_note: "Your account will be deleted permanently",
    notification: "Notification",
    you_have_new_order: "You have a new order!",
    you_have_new_reservation: "You have a new reservation!",
    banner_chart_title:
      "<span class='important'>my-outie</span> vs. POS system Comparison Chart",
    calendar: "Calendar",
    please_upload_picture_with_size_smaller_than_3mb_per_picture:
      "Please upload picture with size smaller than 3MB per picture",
    booking_date: "Booking date",
    product_management: "Product Management",
    register_successful:
      "Welcome to my-outie! Please kindly wait for my-outie team to process your registration",
    pending_for_approval: "Pending for Approval",
    create_seller_account: "Create Seller Account",
    sign_out: "Sign out",
    chat: "Chat",
    search_name: "Search name",
    no_conversation: "No conversation",
    no_message: "No message",
    you_are_discussing_this_product: "You are discussing this product",
    copy_successfully: "Copy successfully",
    see_more: "See more",
    see_less: "See less",
    order_id: "ID đơn hàng",
    type_something: "Type something...",
    you_have_been_blocked_by_this_user: "You have been blocked by this user",
    you_have_blocked_this_user: "You have blocked this user",
    block: "Block",
    unblock: "Unblock",
    do_you_want_to_block_this_conversation:
      "Do you want to block this conversation?",
    do_you_want_to_unblock_this_conversation:
      "Do you want to unblock this conversation?",
    if_you_block_you_will_no_longer_be_able_to_message_this_person:
      "If you block, you will no longer be able to message this person",
    if_you_unblock_you_can_message_this_person:
      "If you unblock, you can message this person",
    you_have_a_new_message: "You have a new message",
    the_store_has_been_disabled: "The store has been disabled",
  },
};
