import React from "react";
import { Route } from "react-router-dom";

import ApiConstant from "../../service/api/api.constant";
import { SERVICE_OWNER_ROUTES } from "../routeConst";
import AuthRouteProps from "./AuthRoute";

const AuthRoute: React.FC<AuthRouteProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  component: Component = () => {},
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (localStorage.getItem(ApiConstant.ACCESS_TOKEN)) {
          return <Component {...props} />;
        }

        window.location.href = SERVICE_OWNER_ROUTES.AUTH;
      }}
    />
  );
};

export default AuthRoute;
