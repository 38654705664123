import { notification } from "antd";

import i18n from "../translation";

export const handleServerError = (error) => {
  const message = error.response?.data?.data?.message;
  notification.error({
    message: i18n.t("failed"),
    description: message || "Something went wrong.",
  });
};
