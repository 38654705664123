import { ConfigProvider, notification } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";

import Routers from "./routes";
import en from "antd/lib/locale/en_US";
import vi from "antd/lib/locale/vi_VN";
import UserStore from "./service/user/user.recoil";
import ApiConstant from "./service/api/api.constant";
import UserRepo from "./service/user/user.repo";
import { OrderAPI } from "./service/order";
import { ReservationApi } from "./service/reservation";

import "./scss/main.scss";
import "./scss/theme.less";

notification.config({
  placement: "top",
});

const App: React.FC = () => {
  const { i18n } = useTranslation();
  const language = localStorage.getItem("language") || "en";
  const setUserInfo = useSetRecoilState(UserStore.info);
  const userInfo = useRecoilValue(UserStore.info);
  // useSocket();

  useEffect(() => {
    localStorage.setItem("language", "en");
    i18n.changeLanguage(language);
  }, []);

  const getUserInfo = async () => {
    const token = localStorage.getItem(ApiConstant.ACCESS_TOKEN);

    if (token) {
      const userInfo = await UserRepo.getMe();
      setUserInfo(userInfo);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  // useEffect(() => {
  //   const token = localStorage.getItem(ApiConstant.ACCESS_TOKEN);

  //   if (token) {
  //     OrderAPI.getList({
  //       offset: 0,
  //       limit: 10,
  //     });
  //   }
  // }, []);

  // useEffect(() => {
  //   if (userInfo?.company?._id) {
  //     ReservationApi.getList(userInfo?.company._id, 0, 10);
  //   }
  // }, [userInfo?.company?._id]);

  return (
    <ConfigProvider locale={language === "en" ? en : vi}>
      <Routers />
    </ConfigProvider>
  );
};

export default App;
