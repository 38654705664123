import { atom } from "recoil";

import { User } from "./user.type";

const info = atom<User | null>({
  key: "infoState",
  default: null,
});

const UserStore = {
  info,
};

export default UserStore;
