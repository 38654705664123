import axios, { AxiosInstance } from "axios";

import ApiConstant from "./api.constant";

const Api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

Api.interceptors.request.use((config) => {
  if (!config.headers) {
    return config;
  }

  const token = localStorage.getItem(ApiConstant.ACCESS_TOKEN) || "";
  const tempToken = localStorage.getItem(ApiConstant.TEMP_ACCESS_TOKEN) || "";
  config.headers.common["authorization"] = `Bearer ${token || tempToken}`;

  return config;
});

Api.interceptors.response.use(
  (res) => {
    return res.data;
    //TODO
    // return res.data
  },
  function (err) {
    const status = err?.response?.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem(ApiConstant.ACCESS_TOKEN);
      localStorage.removeItem(ApiConstant.TEMP_ACCESS_TOKEN);
      window.location.href = "/auth/sign-in";
    }
    return Promise.reject(err);
  }
);

export default Api;
