import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Loading from "../pages/public/Loading";
import AuthRoute from "./AuthRoute";
import RouteConfig from "./config";

const Routers: React.FC = () => {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Switch>
          {RouteConfig.map((route, index) => {
            const RenderComponent = route.auth ? (
              <AuthRoute
                key={index}
                component={route.component}
                exact={route.exact}
                path={route.path}
              />
            ) : (
              <Route
                exact={route.exact}
                path={route.path}
                component={route.component}
                key={index}
              />
            );
            return RenderComponent;
          })}
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routers;
