import React from "react";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import en from "javascript-time-ago/locale/en.json";
import TimeAgo from "javascript-time-ago";

import App from "./App";
import "./translation";
import ConfirmModal from "./components/Modals/ConfirmModal";

TimeAgo.addDefaultLocale(en);

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <RecoilNexus />
      <App />
      <ConfirmModal />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
);
