import { atom } from "recoil";

interface ShowConfirmModal {
  open: boolean;
  onConfirm?: () => Promise<void> | void;
  title?: string;
  description?: string;
}

const showConfirmModal = atom<ShowConfirmModal>({
  key: "showConfirmModal",
  default: {
    open: false,
  },
});

const CommonAtom = {
  showConfirmModal,
};

export default CommonAtom;
