import React from "react";
import { Redirect } from "react-router-dom";

import ApiConstant from "../service/api/api.constant";
import { PUBLIC_ROUTES, SERVICE_OWNER_ROUTES } from "./routeConst";

const AuthLoadableComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AuthContainer" */ "../pages/public/AuthContainer"
    )
);

const CreateCompanyLoadableComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CreateCompany" */ "../pages/service-owner/CreateNewCompany"
    )
);

const ProductListLoadableComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ProductList" */ "../pages/service-owner/ProductList"
    )
);

const ProductDetailsLoadableComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ProductList" */ "../pages/service-owner/ProductDetails"
    )
);

const CreateNewProductLoadableComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CreateNewProduct" */ "../pages/service-owner/CreateNewProduct"
    )
);

const DashboardLoadableComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Dashboard" */ "../pages/service-owner/Dashboard"
    )
);

const PaymentsLoadableComponent = React.lazy(
  () =>
    import(/* webpackChunkName: "Payments" */ "../pages/service-owner/Payments")
);

const CreateNewPaymentLoadableComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CreatePayment" */ "../pages/service-owner/CreateNewPayment"
    )
);

const QRCodeLoadableComponent = React.lazy(
  () => import(/* webpackChunkName: "QRCode" */ "../pages/service-owner/QRCode")
);

// 20211012 DanhVT menu qrcode [
const MenusLoadableComponent = React.lazy(
  () => import(/* webpackChunkName: "Menus" */ "../pages/service-owner/Menus")
);

const MenuLoadableComponent = React.lazy(
  () => import(/* webpackChunkName: "Menu" */ "../pages/service-owner/Menu")
);
// 20211012 DanhVT menu qrcode ]

const ServicePackagesLoadableComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ServicePackages" */ "../pages/service-owner/ServicePackages"
    )
);

const ProfileLoadableComponent = React.lazy(
  () =>
    import(/* webpackChunkName: "Profile" */ "../pages/service-owner/Profile")
);

const EditCompanyProfileLoadableComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "EditCompanyProfile" */ "../pages/service-owner/EditCompanyProfile"
    )
);

const EditPersonalProfileLoadableComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "EditPersonalProfile" */ "../pages/service-owner/EditPersonalProfile"
    )
);

const NotFoundLoadableComponent = React.lazy(
  () => import(/* webpackChunkName: "NotFound" */ "../pages/public/NotFound")
);

const TermsOfUseLoadableComponent = React.lazy(
  () =>
    import(/* webpackChunkName: "TermsOfUse" */ "../pages/public/TermsOfUse")
);

const TermsOfUseVILoadableComponent = React.lazy(
  () =>
    import(/* webpackChunkName: "TermsOfUse" */ "../pages/public/TermsOfUse-vi")
);

const PrivacyLoadableComponent = React.lazy(
  () => import(/* webpackChunkName: "NotFound" */ "../pages/public/Privacy")
);

const PrivacyVILoadableComponent = React.lazy(
  () => import(/* webpackChunkName: "NotFound" */ "../pages/public/Privacy-vi")
);

const ForgotPasswordLoadableComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ForgotPassword" */ "../pages/public/ForgotPassword"
    )
);

const AboutLoadableComponent = React.lazy(
  () => import(/* webpackChunkName: "About" */ "../pages/service-owner/About")
);

const CategoryListLoadableComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CategoryList" */ "../pages/service-owner/Category"
    )
);

const CreateNewCategoryLoadableComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CreateNewCategory" */ "../pages/service-owner/CreateNewCategory"
    )
);

const EditOpeningHoursLoadableComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "EditOpeningHours" */ "../pages/service-owner/EditOpeningHours"
    )
);

const ReservationListLoadableComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ReservationList" */ "../pages/service-owner/Reservation"
    )
);

const ReservationOpeningHoursLoadableComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ReservationOpeningHours" */ "../pages/service-owner/ReservationOpeningHours"
    )
);

const ReservationSettingsLoadableComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ReservationSettings" */ "../pages/service-owner/ReservationSettings"
    )
);

const ReservationDetailsLoadableComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ReservationDetails" */ "../pages/service-owner/ReservationDetails"
    )
);

const OrderListLoadableComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "OrderList" */ "../pages/service-owner/OrderList"
    )
);

const OrderDetailsLoadableComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "OrderList" */ "../pages/service-owner/OrderDetails"
    )
);

const ResetPasswordLoadableComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ResetPassword" */ "../pages/public/ResetPassword"
    )
);

const ChatLoadableComponent = React.lazy(
  () => import(/* webpackChunkName: "Chat" */ "../pages/service-owner/Chat")
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function HomePage() {
  const isAuthenticated = !!localStorage.getItem(ApiConstant.ACCESS_TOKEN);
  if (isAuthenticated) {
    // return <PageLoading />;
    return <Redirect to={SERVICE_OWNER_ROUTES.PRODUCTS} />;
  }

  return <Redirect to={SERVICE_OWNER_ROUTES.AUTH} />;
}

const routes = [
  // service owner routes
  {
    path: SERVICE_OWNER_ROUTES.HOME,
    component: HomePage,
    exact: true,
    auth: false,
  },
  {
    path: SERVICE_OWNER_ROUTES.AUTH,
    component: AuthLoadableComponent,
    exact: false,
    auth: false,
  },
  {
    path: SERVICE_OWNER_ROUTES.CREATE_COMPANY,
    component: CreateCompanyLoadableComponent,
    exact: true,
    auth: false,
  },
  {
    path: SERVICE_OWNER_ROUTES.EDIT_COMPANY_PROFILE,
    component: EditCompanyProfileLoadableComponent,
    exact: true,
    auth: true,
  },
  {
    path: SERVICE_OWNER_ROUTES.EDIT_PERSONAL_PROFILE,
    component: EditPersonalProfileLoadableComponent,
    exact: true,
    auth: true,
  },
  {
    path: SERVICE_OWNER_ROUTES.DASHBOARD,
    component: DashboardLoadableComponent,
    exact: true,
    auth: true,
  },
  {
    path: SERVICE_OWNER_ROUTES.CHAT,
    component: ChatLoadableComponent,
    exact: true,
    auth: true,
  },
  {
    path: SERVICE_OWNER_ROUTES.PRODUCTS,
    component: ProductListLoadableComponent,
    exact: true,
    auth: true,
  },
  {
    path: SERVICE_OWNER_ROUTES.PRODUCTS_DETAILS,
    component: ProductDetailsLoadableComponent,
    exact: true,
    auth: true,
  },
  {
    path: SERVICE_OWNER_ROUTES.NEW_PRODUCT,
    component: CreateNewProductLoadableComponent,
    exact: true,
    auth: true,
  },
  {
    path: `${SERVICE_OWNER_ROUTES.EDIT_PRODUCT}/:id`,
    component: CreateNewProductLoadableComponent,
    exact: true,
    auth: true,
  },
  {
    path: SERVICE_OWNER_ROUTES.PAYMENTS,
    component: PaymentsLoadableComponent,
    exact: false,
    auth: true,
  },
  {
    path: SERVICE_OWNER_ROUTES.NEW_PAYMENT,
    component: CreateNewPaymentLoadableComponent,
    exact: false,
    auth: true,
  },
  // {
  //   path: SERVICE_OWNER_ROUTES.QR_CODE,
  //   component: QRCodeLoadableComponent,
  //   exact: false,
  //   auth: true,
  // },
  // {
  //   path: SERVICE_OWNER_ROUTES.MENUS,
  //   component: MenusLoadableComponent,
  //   exact: false,
  //   auth: true,
  // },
  // {
  //   path: SERVICE_OWNER_ROUTES.MENU,
  //   component: MenuLoadableComponent,
  //   exact: true,
  //   auth: true,
  // },
  // {
  //   path: SERVICE_OWNER_ROUTES.SERVICE_PACKAGES,
  //   component: ServicePackagesLoadableComponent,
  //   exact: false,
  //   auth: true,
  // },
  // {
  //   path: SERVICE_OWNER_ROUTES.ABOUT,
  //   component: AboutLoadableComponent,
  //   exact: false,
  //   auth: true,
  // },
  {
    path: SERVICE_OWNER_ROUTES.PROFILE,
    component: ProfileLoadableComponent,
    exact: false,
    auth: true,
  },
  // public routes
  {
    path: PUBLIC_ROUTES.TERMS_OF_USE,
    component: TermsOfUseLoadableComponent,
    exact: true,
    auth: false,
  },
  {
    path: PUBLIC_ROUTES.TERMS_OF_USE_VI,
    component: TermsOfUseVILoadableComponent,
    exact: true,
    auth: false,
  },
  {
    path: PUBLIC_ROUTES.PRIVACY,
    component: PrivacyLoadableComponent,
    exact: true,
    auth: false,
  },
  {
    path: PUBLIC_ROUTES.PRIVACY_VI,
    component: PrivacyVILoadableComponent,
    exact: true,
    auth: false,
  },
  {
    path: PUBLIC_ROUTES.FORGOT_PASSWORD,
    component: ForgotPasswordLoadableComponent,
    exact: true,
    auth: false,
  },
  {
    path: PUBLIC_ROUTES.RESET_PASSWORD,
    component: ResetPasswordLoadableComponent,
    exact: true,
    auth: false,
  },
  // {
  //   path: SERVICE_OWNER_ROUTES.CATEGORY,
  //   component: CategoryListLoadableComponent,
  //   exact: true,
  //   auth: true,
  // },
  // {
  //   path: SERVICE_OWNER_ROUTES.NEW_CATEGORY,
  //   component: CreateNewCategoryLoadableComponent,
  //   exact: true,
  //   auth: true,
  // },
  // {
  //   path: SERVICE_OWNER_ROUTES.EDIT_CATEGORY,
  //   component: CreateNewCategoryLoadableComponent,
  //   exact: true,
  //   auth: true,
  // },
  // {
  //   path: SERVICE_OWNER_ROUTES.EDIT_OPENING_HOURS,
  //   component: EditOpeningHoursLoadableComponent,
  //   exact: true,
  //   auth: true,
  // },
  // {
  //   path: SERVICE_OWNER_ROUTES.RESERVATION,
  //   component: ReservationListLoadableComponent,
  //   exact: true,
  //   auth: true,
  // },
  // {
  //   path: SERVICE_OWNER_ROUTES.RESERVATION_SETTINGS,
  //   component: ReservationSettingsLoadableComponent,
  //   exact: true,
  //   auth: true,
  // },
  // {
  //   path: SERVICE_OWNER_ROUTES.RESERVATION_OPENING_HOURS,
  //   component: ReservationOpeningHoursLoadableComponent,
  //   exact: true,
  //   auth: true,
  // },
  // {
  //   path: SERVICE_OWNER_ROUTES.RESERVATION_DETAILS,
  //   component: ReservationDetailsLoadableComponent,
  //   exact: true,
  //   auth: true,
  // },
  // {
  //   path: SERVICE_OWNER_ROUTES.ORDERS,
  //   component: OrderListLoadableComponent,
  //   exact: true,
  //   auth: true,
  // },
  // {
  //   path: SERVICE_OWNER_ROUTES.ORDERS_DETAILS,
  //   component: OrderDetailsLoadableComponent,
  //   exact: true,
  //   auth: true,
  // },
  {
    path: PUBLIC_ROUTES.NOT_FOUND,
    component: NotFoundLoadableComponent,
    auth: false,
  },
];

export default routes;
